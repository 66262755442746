import React, { useState } from 'react'
import {
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonChip,
    IonText,
    IonItem,
    IonLabel,
    IonToggle,
    IonInput,
    IonToast,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon
} from '@ionic/react'
import { format } from 'date-fns'
import axios from 'axios'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'
import StandardContainer from '../../commonComponents/StandardContainer'
import TimeSelector from '../../commonComponents/timeSelector'

const EmployeeHoursModal = ({ isOpen, onClose, employee, date, refreshAppointments }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [enabled, setEnabled] = useState(employee?.workingStartTime ? true : false)
    const [startTime, setStartTime] = useState(employee?.workingStartTime || '')
    const [endTime, setEndTime] = useState(employee?.workingEndTime || '')
    const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
    const [errorMessage, setErrorMessage] = useState('')

    const modalStyles = {
        '--border-radius': '16px',
        '--width': '100%',
        '--max-width': '600px'
    }

    const showToastMessage = (message, color = 'success') => {
        setToast({ isOpen: true, message, color })
    }

    const handleSave = async () => {
        setErrorMessage('')
        if (enabled && (!startTime || !endTime)) {
            setErrorMessage('**Open time and close time must be filled out')
            return
        }

        try {
            const scheduleData = {
                dateText: format(new Date(date), 'MMM d, yyyy'),
                dateEpoch: new Date(date).getTime(),
                enabled,
                startTime,
                endTime,
                employeeId: employee._id,
                locationId: employee.locationId,
                businessId: employee.businessId
            }

            if (employee?.modifiedSchedule?._id) {
                await axios.patch(`/employee/modifiedSchedule/${employee.modifiedSchedule._id}`, scheduleData)
            } else {
                await axios.post('/employee/modifiedSchedule', scheduleData)
            }

            showToastMessage('Schedule updated successfully')
            setIsEditing(false)
            refreshAppointments()
        } catch (error) {
            console.error('Error saving schedule:', error)
            showToastMessage('Failed to update schedule. Please try again.', 'danger')
        }
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles}>
            <IonContent>
                <StandardContainer padding={20}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <h2>
                                    {employee?.firstName} {employee?.lastName}'s Schedule
                                </h2>
                            </IonCol>
                            <IonCol size='auto'>
                                <IonIcon
                                    onClick={onClose}
                                    style={{ fontSize: fontSizes.size24, color: colors.black40, fontWeight: fontWeights.weightBold }}
                                    icon={closeOutline}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    {isEditing ? (
                        <IonGrid style={{ marginBottom: 80, flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                            <IonRow style={{ padding: 10, border: `1px solid ${colors.black30}`, borderRadius: 10 }}>
                                <IonCol>
                                    <IonRow>
                                        <IonLabel style={{ fontWeight: fontWeights.weightBold }}>
                                            Is {employee?.firstName} working this day?
                                        </IonLabel>
                                    </IonRow>
                                    <IonRow style={{ marginTop: 10 }}>
                                        <IonLabel style={{ color: colors.black40 }}>
                                            Check the box to indicate yes
                                        </IonLabel>
                                    </IonRow>
                                </IonCol>
                                <IonCol size='auto' style={{ display: 'flex', alignItems: 'center' }}>
                                    <IonToggle
                                        mode='ios'
                                        checked={enabled}
                                        onIonChange={e => setEnabled(e.detail.checked)}
                                    />
                                    {enabled && (
                                        <IonIcon
                                            onClick={() => setEnabled(false)}
                                            icon={checkmarkOutline}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: 11,
                                                zIndex: 10,
                                                marginLeft: '8px',
                                                color: colors.primaryBlue
                                            }}
                                        />
                                    )}
                                </IonCol>
                            </IonRow>

                            {enabled && (
                                <>
                                    <IonRow
                                        style={{
                                            marginTop: 30,
                                            padding: 10,
                                            border: `1px solid ${colors.black30}`,
                                            borderBottom: 'none',
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IonLabel>Start Time</IonLabel>
                                        <TimeSelector dayKey={employee._id} fieldKey='startTime' value={startTime} handleChange={(dayKey, fieldKey, value)=>{ setStartTime(value); }} />
                                    </IonRow>
                                    <IonRow
                                        style={{
                                            padding: 10,
                                            border: `1px solid ${colors.black30}`,
                                            borderBottomLeftRadius: 10,
                                            borderBottomRightRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IonLabel>End Time</IonLabel>
                                        <TimeSelector dayKey={employee._id} fieldKey='endTime' minTime={startTime} value={endTime} handleChange={(dayKey, fieldKey, value)=>{ setEndTime(value); }} />
                                    </IonRow>
                                </>
                            )}

                            <IonText color='danger'>
                                <p>{errorMessage}</p>
                            </IonText>

                            {/* Fixed Footer */}
                            <div
                                style={{
                                    zIndex: 10,
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    background: 'white',
                                    padding: '10px 20px 40px 20px',
                                    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <IonButton fill='clear' color={'dark'} onClick={() => setIsEditing(false)}>
                                    Cancel
                                </IonButton>
                                <IonButton
                                    style={{
                                        color: 'white',
                                        fontWeight: '600',
                                        justifyContent: 'center',
                                        alignItems: 'end',
                                        flexDirection: 'column',
                                        display: 'flex',
                                    }}
                                    color={'dark'}
                                    onClick={handleSave}
                                >
                                    <IonLabel style={{ padding: '0 22px' }}>Save</IonLabel>
                                </IonButton>
                            </div>
                        </IonGrid>
                    ) : (
                        <div style={{ 
                            marginTop: '20px',
                            padding: '24px',
                            border: `1px solid ${colors.black10}`,
                            borderRadius: '16px',
                            backgroundColor: colors.white
                        }}>
                            <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px'
                            }}>
                                <div 
                                    onClick={() => setIsEditing(true)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.2s ease',
                                        padding: '8px',
                                        margin: '-8px',
                                        borderRadius: '8px',
                                        ':hover': {
                                            backgroundColor: colors.black05
                                        }
                                    }}>
                                    <div style={{
                                        width: '48px',
                                        height: '48px',
                                        borderRadius: '12px',
                                        backgroundColor: colors.black05,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexShrink: 0
                                    }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colors.black60} strokeWidth="2">
                                            <circle cx="12" cy="12" r="10"/>
                                            <polyline points="12 6 12 12 16 14"/>
                                        </svg>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        flex: 1
                                    }}>
                                        <div style={{
                                            fontSize: '16px',
                                            fontWeight: fontWeights.weightBold,
                                            color: colors.black90
                                        }}>
                                            {enabled ? 'Working Hours' : 'Not Working'}
                                        </div>
                                        {enabled && (
                                            <div style={{
                                                fontSize: '15px',
                                                color: colors.black60
                                            }}>
                                                {startTime} - {endTime}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{
                                        fontSize: '15px',
                                        color: colors.primaryBlue,
                                        fontWeight: '600',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                        Edit

                                    </div>
                                </div>

                                {/* {employee?.modifiedSchedule && (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px',
                                        paddingTop: '16px',
                                        borderTop: `1px solid ${colors.black10}`
                                    }}>
                                        <div style={{
                                            width: '48px',
                                            height: '48px',
                                            borderRadius: '12px',
                                            backgroundColor: colors.black05,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colors.black60} strokeWidth="2">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                                <polyline points="17 8 12 3 7 8"/>
                                                <line x1="12" y1="3" x2="12" y2="15"/>
                                            </svg>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px'
                                        }}>
                                            <div style={{
                                                fontSize: '16px',
                                                fontWeight: fontWeights.weightBold,
                                                color: colors.black90
                                            }}>
                                                Modified Schedule
                                            </div>
                                            <div style={{
                                                fontSize: '15px',
                                                color: colors.black60
                                            }}>
                                                This is a custom schedule for this day
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    )}
                </StandardContainer>
            </IonContent>

            <IonToast
                position="top"
                isOpen={toast.isOpen}
                onDidDismiss={() => setToast({ ...toast, isOpen: false })}
                message={toast.message}
                color={toast.color}
                duration={2000}
            />
        </IonModal>
    )
}

export default EmployeeHoursModal 