import { IonSelect, IonSelectOption } from '@ionic/react'

const times = [
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
]
const times15min = [
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
]

export default function TimeSelector({ dayKey, fieldKey, value, handleChange, useFifteenMin, minTime }) {
  const valueChanged = (event) => {
    handleChange(dayKey, fieldKey, event.target.value)
  }

  let timesList = useFifteenMin ? times15min : times

  // Check if current value is invalid
  const isInvalidTime = minTime && value ? (() => {
    const [minHours, minMinutes] = minTime.split(':').map(part => parseInt(part.replace(/[APM]/g, '')));
    const [valueHours, valueMinutes] = value.split(':').map(part => parseInt(part.replace(/[APM]/g, '')));
    
    const minTime24 = minHours + (minTime.includes('PM') && minHours !== 12 ? 12 : 0);
    const value24 = valueHours + (value.includes('PM') && valueHours !== 12 ? 12 : 0);
    
    if (value24 < minTime24) return true;
    if (value24 === minTime24 && valueMinutes <= minMinutes) return true;
    return false;
  })() : false;

  // Filter times if minTime exists
  if (minTime) {
    timesList = timesList.filter(time => {
      const [minHours, minMinutes] = minTime.split(':').map(part => parseInt(part.replace(/[APM]/g, '')));
      const [timeHours, timeMinutes] = time.split(':').map(part => parseInt(part.replace(/[APM]/g, '')));
      
      const minTime24 = minHours + (minTime.includes('PM') && minHours !== 12 ? 12 : 0);
      const time24 = timeHours + (time.includes('PM') && timeHours !== 12 ? 12 : 0);
      
      if (time24 > minTime24) return true;
      if (time24 === minTime24) return timeMinutes > minMinutes;
      return false;
    });
  }

  return (
    <div>
      <IonSelect 
        onIonChange={valueChanged} 
        value={value} 
        placeholder={value || 'Select Time'}
        style={{
          borderBottom: isInvalidTime ? '2px solid var(--ion-color-danger)' : 'none', marginTop:isInvalidTime ? -2 : null
        }}
      >
        {timesList.map((v) => (
          <IonSelectOption value={v}>{v}</IonSelectOption>
        ))}
      </IonSelect>
    </div>
    //   <FormControl variant='standard' sx={{ minWidth: 90, maxHeight: 40, margin: 0, fontSize: 10 }} margin='dense' size='small'>
    //     <Select labelId='demo-select-small' id='demo-select-small' value={value} label={fieldKey} onChange={valueChanged} style={{ fontSize: 14 }}>
    //       {timesList.map((v) => (
    //         <MenuItem value={v}>{v}</MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>
  )
}
