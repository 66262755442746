import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route } from 'react-router-dom'
import './firebase'
// import Login from './login2'

import { AuthProvider } from './auth'

import MainTabs from './AppMainTabs'
import Login from './login'
import SignUp from './signup'
import './App.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Theme variables */
import BusinessSetupFlow from './business/setup'
import Home from './Home'
import JoinBusiness from './JoinBusiness'
import UserNotice from './Notice'
import RegisterNewUserToBusiness from './registerNewUser'
import SetupBusinessDetails from './SetupBusinessDetails'
import './theme/variables.css'
import PendingInvitationsList from './users/PendingInvitations'
import BusinessCreateFlow from './business/setup/create'
import BusinessInviteFlow from './business/setup/invite'
import BusinessTabs from './BusinessSection/Tabs'
import { SocketProvider } from './contexts/SocketContext'
import ResourceCalendarDay from './appointments/AI_Calendar'

setupIonicReact()

const App = () => (

  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <AuthProvider>
          <SocketProvider>
            <Route exact path='/login' component={Login} />
            <Route exact path='/notice' component={UserNotice} />
            <Route exact path='/signup' component={SignUp} />
            {/* <Route exact path='/business' component={BusinessDashboard} />
            <Route exact path='/businessSettings' component={BusinessSettings} /> */}
            <Route exact path='/registerNewUser' component={RegisterNewUserToBusiness} />
            <Route exact path='/setup' component={BusinessSetupFlow} />
            <Route exact path='/setup/create' component={BusinessCreateFlow} />
            <Route exact path='/setup/invite' component={BusinessInviteFlow} />
            <Route exact path='/setupBusinessDetails' component={SetupBusinessDetails} />
            <Route exact path='/joinBusiness' component={JoinBusiness} />
            <Route exact path='/pendingInvitations' component={PendingInvitationsList} />
            <Route path='/core/*' component={MainTabs} />
            <Route path='/business/*' component={BusinessTabs} />
            <Route exact path='/' component={Home} />
            
          </SocketProvider>
        </AuthProvider>
        {/* <Route exact path='/'>
            <Redirect to='/core/dashboard' />
          </Route> */}
          <Route exact path='/resourceCalendar' component={ResourceCalendarDay} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>

)

export default App
