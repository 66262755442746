import React from 'react'
import { AuthContext } from './auth'

const access = {
  UN_AUTHENTICATED: 'UN_AUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  PROFESSIONAL: 'PROFESSIONAL',
  MANAGER: 'MANAGER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
}

const NOT_LOGGED_IN = [access.UN_AUTHENTICATED, access.AUTHENTICATED, access.PROFESSIONAL, access.MANAGER, access.ADMIN, access.OWNER]
const PROFESSIONAL_PLUS = [access.PROFESSIONAL, access.MANAGER, access.ADMIN, access.OWNER]
const MANAGER_PLUS = [access.MANAGER, access.ADMIN, access.OWNER]
const ADMIN_PLUS = [access.ADMIN, access.OWNER]
const OWNER_ONLY = [access.OWNER]

const components = {
  create_location_button: ADMIN_PLUS,
  create_business_button: null,
  location_general: ADMIN_PLUS,
  location_schedule: MANAGER_PLUS,
  location_advanced_all: ADMIN_PLUS,
  location_public_toggle: ADMIN_PLUS,
  dashboard_progress_chart: PROFESSIONAL_PLUS,
  dashboard_today_card: PROFESSIONAL_PLUS,
  dashboard_stats: ADMIN_PLUS,
  dashboard_appointment_trends: MANAGER_PLUS,
  dashboard_cancel_no_show_graph: MANAGER_PLUS,
  dashboard_unread_messages: PROFESSIONAL_PLUS,
  business_permissions: ADMIN_PLUS,
  customer_advanced_settings: ADMIN_PLUS,
  custoemr_credit_balance: MANAGER_PLUS,
  owner_permission: OWNER_ONLY,
  admin_permission: ADMIN_PLUS,
  manager_permission: MANAGER_PLUS,
  professional_permission: PROFESSIONAL_PLUS,
  switch_locations_list_item: PROFESSIONAL_PLUS,
  cancel_appointment: MANAGER_PLUS,
  appointment_history: MANAGER_PLUS,
  legacy_service_value: MANAGER_PLUS,
  change_employee_post_complete: ADMIN_PLUS,
  locationStats_revenue: ADMIN_PLUS,
  locaitonStats_revenue_from_subs: OWNER_ONLY,
  more_ai_tools: ADMIN_PLUS,
  deleteService: ADMIN_PLUS,
  deleteProduct: ADMIN_PLUS,
  cashManagementAccount_professional: PROFESSIONAL_PLUS,
  cashManagementAccount_manager: MANAGER_PLUS,
  cashManagementAccount_admin: ADMIN_PLUS,
  cashManagementAccount_owner: OWNER_ONLY,
  employee_details_changes: MANAGER_PLUS,
}

export const userHasAccess = (component, user, businessId, locationId) => {
  let accessType = user ? access.AUTHENTICATED : access.UN_AUTHENTICATED

  if (user.businesses && businessId && locationId) {
    const combinedEntitlements = getCombinedEntitlements(businessId, locationId, user?.businesses)

    if (combinedEntitlements?.owner) {
      accessType = access.OWNER
    } else if (combinedEntitlements?.admin) {
      accessType = access.ADMIN
    } else if (combinedEntitlements?.manager) {
      accessType = access.MANAGER
    } else if (combinedEntitlements?.professional) {
      accessType = access.PROFESSIONAL
    }
  }

  // console.log(accessType)
  return components[component]?.includes(accessType) ?? false
}

function getCombinedEntitlements(businessId, locationId, businesses) {
  try {
    // Find the specific business by ID
    const business = businesses.find((b) => b._id === businessId)
    if (!business) {
      throw new Error('Business not found')
    }

    // Default business-level entitlements (global entitlements)
    const businessEntitlements = {
      owner: business.entitlements?.owner || false,
      admin: business.entitlements?.admin || false,
      manager: business.entitlements?.manager || false,
      professional: business.entitlements?.professional || false,
    }

    // Function to combine entitlements where 'true' always wins
    const combineEntitlements = (ent1, ent2) => {
      return {
        owner: ent1?.owner || ent2?.owner,
        admin: ent1?.admin || ent2?.admin,
        professional: ent1?.professional || ent2?.professional,
        manager: ent1?.manager || ent2?.manager,
      }
    }

    // Find the specific location by ID
    const location = business.locations.find((loc) => loc._id === locationId)
    if (!location) {
      // throw new Error("Location not found");
    }

    // Combine business and location entitlements, with 'true' being the winning value
    const combinedEntitlements = combineEntitlements(businessEntitlements, location?.entitlements)

    // Return the combined entitlements for this specific location
    return combinedEntitlements
  } catch (error) {
    console.error('Error in getCombinedEntitlements:', error)
    return null
  }
}

// AccessControl component
export const AccessControl = ({ componentName, children, locationId, businessLevelEntitlementsOnly, accessDeniedComponent }) => {
  const { userData, businessData, selectedLocationData } = React.useContext(AuthContext)
  const hasAccess = userHasAccess(componentName, userData, businessData?._id, businessLevelEntitlementsOnly ? 'none' : locationId || selectedLocationData._id)

  if (hasAccess) {
    return <>{children}</>
  } else {
    return accessDeniedComponent || null
  }
}
