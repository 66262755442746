import React, { useState, useEffect } from 'react';
import {
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonToast,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonAlert
} from '@ionic/react';
import { closeOutline, timeOutline, trashOutline } from 'ionicons/icons';
import { colors } from '../../theme/colors';
import { fontSizes, fontWeights } from '../../theme/typography';
import axios from 'axios';
import AddBreak from '../CalendarDayView/AddBreak';
import StandardContainer from '../../commonComponents/StandardContainer';
import TimeSelector from '../../commonComponents/timeSelector';

const BreakModal = ({ isOpen, onClose, breakData, onBreakUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [toast, setToast] = useState({ isOpen: false, message: '', color: '' });
    const [startTime, setStartTime] = useState(breakData?.startTimeText || '');
    const [endTime, setEndTime] = useState(breakData?.endTimeText || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    // Reset state when modal closes
    useEffect(() => {
        if (!isOpen) {
            setIsEditing(false);
            setStartTime(breakData?.startTimeText || '');
            setEndTime(breakData?.endTimeText || '');
            setErrorMessage('');
            setShowDeleteAlert(false);
            setToast({ isOpen: false, message: '', color: '' });
        }
    }, [isOpen, breakData]);

    const handleClose = () => {
        onClose();
    };

    const modalStyles = {
        '--border-radius': '16px',
        '--width': '100%',
        '--max-width': '600px'
    };

    const handleSaveBreak = async () => {
        setErrorMessage('');
        if (!startTime || !endTime) {
            setErrorMessage('**Start time and end time must be filled out');
            return;
        }

        try {
            await axios.patch(`/employee/break/${breakData._id}`, {
                startTime,
                endTime
            });
            setToast({ isOpen: true, message: 'Break updated successfully!', color: 'success' });
            onBreakUpdate();
            setIsEditing(false);
        } catch (error) {
            console.log(error);
            setToast({ isOpen: true, message: 'Error updating break. Please try again.', color: 'danger' });
        }
    };

    const handleDeleteBreak = async () => {
        try {
            await axios.delete(`/employee/break?id=${breakData._id}`);
            setToast({ isOpen: true, message: 'Break deleted successfully!', color: 'success' });
            onBreakUpdate();
            onClose();
        } catch (error) {
            setToast({ isOpen: true, message: 'Error deleting break. Please try again.', color: 'danger' });
        }
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={handleClose} style={modalStyles}>
            <IonContent>
                <StandardContainer padding={20}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <h2 style={{ marginBottom: '4px' }}>
                                    {breakData?.employee?.firstName} {breakData?.employee?.lastName}'s Break
                                </h2>
                                <div style={{ 
                                    fontSize: '15px',
                                    color: colors.black60,
                                    marginBottom: '8px'
                                }}>
                                    {startTime} - {endTime}
                                </div>
                            </IonCol>
                            <IonCol size='auto'>
                                <IonIcon
                                    onClick={handleClose}
                                    style={{ 
                                        fontSize: fontSizes.size24, 
                                        color: colors.black40, 
                                        fontWeight: fontWeights.weightBold,
                                        cursor: 'pointer'
                                    }}
                                    icon={closeOutline}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    {isEditing ? (
                        <IonGrid style={{ marginBottom: 80, flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                            <IonRow
                                style={{
                                    marginTop: 30,
                                    padding: 10,
                                    border: `1px solid ${colors.black30}`,
                                    borderBottom: 'none',
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <IonLabel>Start Time</IonLabel>
                                <TimeSelector 
                                    dayKey={breakData?._id} 
                                    fieldKey='startTime' 
                                    value={startTime} 
                                    handleChange={(dayKey, fieldKey, value) => { setStartTime(value); }} 
                                />
                            </IonRow>
                            <IonRow
                                style={{
                                    padding: 10,
                                    border: `1px solid ${colors.black30}`,
                                    borderBottomLeftRadius: 10,
                                    borderBottomRightRadius: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <IonLabel>End Time</IonLabel>
                                <TimeSelector 
                                    dayKey={breakData?._id} 
                                    fieldKey='endTime' 
                                    minTime={startTime}
                                    value={endTime} 
                                    handleChange={(dayKey, fieldKey, value) => { setEndTime(value); }} 
                                />
                            </IonRow>

                            <IonText color='danger'>
                                <p>{errorMessage}</p>
                            </IonText>

                            <div 
                                onClick={() => setShowDeleteAlert(true)}
                                style={{ 
                                    marginTop: '2rem',
                                    color: colors.errorText,
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    cursor: 'pointer',
                                    width: 'fit-content'
                                }}
                            >
                                <IonIcon 
                                    icon={trashOutline} 
                                    style={{ 
                                        fontSize: '16px',
                                        color: colors.errorText
                                    }} 
                                />
                                Delete break
                            </div>

                            {/* Fixed Footer */}
                            <div
                                style={{
                                    zIndex: 10,
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    background: 'white',
                                    padding: '10px 20px 40px 20px',
                                    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <IonButton fill='clear' color={'dark'} onClick={() => setIsEditing(false)}>
                                    Cancel
                                </IonButton>
                                <IonButton
                                    style={{
                                        color: 'white',
                                        fontWeight: '600',
                                        justifyContent: 'center',
                                        alignItems: 'end',
                                        flexDirection: 'column',
                                        display: 'flex',
                                    }}
                                    color={'dark'}
                                    onClick={handleSaveBreak}
                                >
                                    <IonLabel style={{ padding: '0 22px' }}>Save</IonLabel>
                                </IonButton>
                            </div>
                        </IonGrid>
                    ) : (
                        <div style={{ 
                            marginTop: '20px',
                            padding: '24px',
                            border: `1px solid ${colors.black10}`,
                            borderRadius: '16px',
                            backgroundColor: colors.white
                        }}>
                            <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px'
                            }}>
                                <div 
                                    onClick={() => setIsEditing(true)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.2s ease',
                                        padding: '8px',
                                        margin: '-8px',
                                        borderRadius: '8px',
                                        ':hover': {
                                            backgroundColor: colors.black05
                                        }
                                    }}>
                                    <div style={{
                                        width: '48px',
                                        height: '48px',
                                        borderRadius: '12px',
                                        backgroundColor: colors.black05,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexShrink: 0
                                    }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colors.black60} strokeWidth="2">
                                            <circle cx="12" cy="12" r="10"/>
                                            <polyline points="12 6 12 12 16 14"/>
                                        </svg>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        flex: 1
                                    }}>
                                        <div style={{
                                            fontSize: '16px',
                                            fontWeight: fontWeights.weightBold,
                                            color: colors.black90
                                        }}>
                                            Break Time
                                        </div>
                                        <div style={{
                                            fontSize: '15px',
                                            color: colors.black60
                                        }}>
                                            {startTime} - {endTime}
                                        </div>
                                    </div>
                                    <div style={{
                                        fontSize: '15px',
                                        color: colors.primaryBlue,
                                        fontWeight: '600',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                        Edit
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </StandardContainer>
            </IonContent>

            <IonAlert
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                header={'Delete Break'}
                message={`Are you sure you want to delete ${breakData?.employee?.firstName}'s break from ${startTime} to ${endTime}?`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Delete',
                        handler: handleDeleteBreak,
                        cssClass: 'danger'
                    }
                ]}
            />

            <IonToast
                position="top"
                isOpen={toast.isOpen}
                onDidDismiss={() => setToast({ ...toast, isOpen: false })}
                message={toast.message}
                color={toast.color}
                duration={2000}
            />
        </IonModal>
    );
};

export default BreakModal; 